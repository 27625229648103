import React, { useEffect, useState } from 'react';
import { Icon, SemanticICONS } from 'semantic-ui-react';
import classNames from 'classnames';
import { Icons } from '../Classes';

export interface ButtonProps{
	text?: string,
	onClick?: (setLoading: (loading: boolean)=>void)=>void,
	nativeOnClick?: (ev?: React.MouseEvent)=>void,
	href?: string,
	basic?: boolean,
	color?: 'green' | 'red' | 'purple' | 'black' | 'blue' | 'orange' | 'basic' | 'white',
	loading?: boolean,
	className?: string
	icon?: boolean,
	iconName?: Icons
	target?: '_blank' | '_self' | '_parent' | '_top',
	size?: 'small' | 'tiny' | 'big',
	disabled?: boolean,
	fluid?: boolean,
	style?: React.CSSProperties,
	onLoadingChanged?: (v: boolean)=>void,
}
var Button = (props: ButtonProps)=>{
	var [loading, setLoading] = useState(false);

	useEffect(()=>{
		if(typeof props.loading!=='undefined' && props.loading!=loading){
			setLoading(props.loading);
			if(props.onLoadingChanged) props.onLoadingChanged(props.loading);
		}
	}, [props]);

	var onClick = (ev: React.MouseEvent)=>{
		if(props.nativeOnClick) props.nativeOnClick(ev);
		if(props.onClick) props.onClick(loading=>{
			setLoading(loading);
			if(props.onLoadingChanged) props.onLoadingChanged(loading);
		});
	}
	
	const ComponentType = props.href ? 'a' : 'div';
	return <ComponentType {...(props.href ? { target: props.target || '_self', href: props.href } : {})} className={classNames('fr button', {
		icon: props.icon,
		loading: loading,
		basic: props.basic,
		disabled: props.disabled,
		fluid: props.fluid,
	}, props.size, props.color, props.className)} style={props.style} onClick={onClick}>
		{props.iconName && <i className={`${props.iconName} icon`}></i>}
		{props.text}
	</ComponentType>
}

export default Button;