import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter, useRouteError, Outlet } from 'react-router-dom';
import Toast, { Toaster } from 'react-hot-toast';
import 'fomantic-ui-css/semantic.css';
import './style/site.scss'

import { Header, Navbar } from './components';
import { Login, UserProjects, Project, UserProfile, UserRegister } from './screens';
import { useEffect, useState } from 'react';
import { UserLoginData } from './Classes';
import API from './API';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

var ErrorElement = ()=>{
	const error = (useRouteError() as any);
	console.log(error);
	return <Header
		text={error.status}
		subtext={error.status===404 ? 'Página no encontrada' : `Hubo un error inesperado. (PBL-LCL-${error.status})`}
		style={{ fontSize: 100 }}
		subheaderStyle={{ fontSize: 20 }}
		containerStyle={{ marginTop: 20 }}
	/>
}

var SiteContainer = (props: { outlet: JSX.Element })=>{
	return <div>
		<Navbar />
		<div style={{ paddingTop: 60 }}>
			{props.outlet}
		</div>
	</div>
}

const Router = createBrowserRouter([{
	path: '/',
	errorElement: <SiteContainer outlet={<ErrorElement />} />,
	element: <SiteContainer outlet={<Outlet />} />,
	children: [
		{ path: '/', element: <UserProjects /> },
		{ path: '/perfil', element: <UserProfile /> },
		{ path: '/register', element: <UserRegister />},
		{ path: '/:projectId*', element: <Project /> },
	]
}]);

var Root = ()=>{
	var [login, setLogin] = useState<UserLoginData>(null);
	useEffect(()=>{
		var ldata = API.getLogin();
		if(ldata) setLogin(ldata);
		var logoutEvent = ()=>{
			var lgn = API.getLogin();
			setLogin(lgn);
		};
		window.addEventListener('user_data', logoutEvent);
		return ()=>{
			window.removeEventListener('user_data', logoutEvent);
		}
	}, []);

	if(!login){
		return <Login />
	}

	return (
		<>
			<Toaster/>
			<RouterProvider router={Router} />
		</>
	)
}


root.render(<Root/>);