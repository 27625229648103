import React, { useEffect, useState } from 'react';
import { Modal, Checkbox, Icon } from 'semantic-ui-react';
import { Button, Input, Message, Header } from '../components';
import { addCommas, bindClick, bindSimpleFormChange } from '../Util';
import { SetLoading } from '../Classes';
import Validator from '../Validator';
import API from '../API';
import Toast from 'react-hot-toast';
import moment from 'moment';

interface PaymentForm{
   project_id: number,
   expense_name: string,
   amount: number,
   description?: string,
   deleted: boolean
}

interface Payment{
   expense_id: number,
   expense_name: string,
   description?: string,
   email: string,
   date_created: number,
   date_deleted?: number,
   amount: number,
   deleted: boolean
}


var ProjectPayments = (props: any)=>{
   var { idProject, edit } = props;
   var [payments, setPayments] = useState<Payment[]>(null);
   var [createModal, setCreateModal] = useState<boolean>(false);
   var [viewModal, setViewModal] = useState<boolean>(false);
	var [createForm, setCreateForm] = useState<PaymentForm>(null);
   var [modalPrompts, setModalPrompts] = useState<string[]>(null);
   var [selectedPayment, setSelectedPayment] = useState<Payment>(null);
   var [loadError, setLoadError] = useState<string>(null);

   useEffect(()=>{
      get();
	}, []);

   var get = ()=>{
      API.getProjectPayments(idProject).then(res=>{
         if(res.error) return setLoadError(res.message);
         return setPayments(res.data);
      }).catch(err=>{
         return setLoadError('Hubo un error al obtener los pagos del proyecto.(GPPE-1)');
      });
   }

   var createPayment = (setLoading: SetLoading)=>{
      var { valid, prompts } = Validator(createForm, {
			expense_name: [
				{ rule: 'length', params: [4], label: 'Nombre' },
			],
         amount: [
				{ rule: 'number', label: 'Monto' },
			]
		});
		setModalPrompts(prompts);
		if(!valid) return;
		setLoading(true);
      API.createPayment( idProject, createForm.expense_name, createForm.amount, createForm.description).then(res=>{
         if(res.error) return Toast.error(res.message);
         setCreateModal(false);
         Toast.success('Gasto creado con exito!');
         return get();
      }).catch(err=>{
         return Toast.error('Hubo un error al registrar el gasto.(GPPE-2)');
      })
   }

   var editPayment = (setLoading: SetLoading)=>{
      var { valid, prompts } = Validator(selectedPayment, {
         expense_id: [
            { rule: 'number', label: 'Numero de pago' }
         ],
			expense_name: [
				{ rule: 'length', params: [4], label: 'Nombre' },
			],
         amount: [
				{ rule: 'number', label: 'Monto' },
			]
		});
		setModalPrompts(prompts);
		if(!valid) return;
		setLoading(true);
      API.editPayment( selectedPayment.expense_id, selectedPayment.expense_name, selectedPayment.amount, selectedPayment.description, selectedPayment.deleted).then(res=>{
         if(res.error) return Toast.error(res.message);
         setViewModal(false);
         Toast.success('Gasto actualizado con exito!');
         return get();
      }).catch(err=>{
         return Toast.error('Hubo un error al actualizar el gasto.(GPPE-3)');
      }).finally(()=>{
			setLoading(false);
		})
   }

   var viewPayment = async (payment: Payment)=>{
      setSelectedPayment(payment);
      return setViewModal(true);
   }

	if(loadError){
		return <Header text='Error' subtext={loadError} />
	}

	if(!payments){
		return <Header loading text='Cargando datos...' />
	}

   var onCreateFormChange = bindSimpleFormChange(createForm, setCreateForm);
   var onEditChange = bindSimpleFormChange(selectedPayment, setSelectedPayment);

   return <div>
      <table className="fr selectable table" style={{ maxWidth: 800, margin: 'auto' }}>
			<thead>
				<tr>
					<th className="title" colSpan={4}>Gastos</th>
					<th colSpan={2} style={{ textAlign: 'right' }}>
                  {edit? <Button size='tiny' color='black' iconName='plus circle' text='Crear' onClick={bindClick(setCreateModal, true)} /> : null}
					</th>
				</tr>
				<tr>
					<th>ID</th>
					<th>Nombre</th>
					<th>Descripción</th>
					<th>Monto</th>
					<th>Creador</th>
               <th>Creación</th>
				</tr>
			</thead>
			<tbody>
				{payments.length === 0 ? (
					<tr>
						<th colSpan={8} style={{ textAlign: 'center', padding: '10px' }}>
                     <b>Sin gastos registrados.</b>
                  </th>
					</tr>
				) : (
					payments.map(payment => (
						<tr onClick={() => viewPayment(payment)}>
							<td className='collapsing'><b>{payment.expense_id}</b></td>
                     <td>{payment.expense_name}</td>
                     <td>
								{payment.description && payment.description.length>0 ? payment.description : (
									<Icon color={'grey'} name="minus" />
								)}
							</td>
                     <td className='collapsing'>{addCommas(payment.amount)}</td>
                     <td className='collapsing'>{payment.email}</td>
                     <td className='collapsing'>{moment.unix(payment.date_created).format('DD/MM/YY')}</td>
						</tr>
					))
				)}
			</tbody>
		</table>
      <Modal open={createModal} onClose={bindClick(setCreateModal)} size='tiny'>
			<Modal.Header>Registrar gasto</Modal.Header>
			<Modal.Content>
            <Input label='Nombre del gasto' onChange={onCreateFormChange('expense_name')} />
            <Input label='Cantidad' onChange={onCreateFormChange('amount')} />
            <Input label='Descripción' onChange={onCreateFormChange('description')} />
				<Message type='error' list={modalPrompts} />
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cerrar' basic onClick={bindClick(setCreateModal)} />
				<Button text='Crear' color='black' onClick={createPayment}/>
			</Modal.Actions>
		</Modal>
      <Modal open={viewModal} onClose={bindClick(setViewModal)} size='tiny'>
			<Modal.Header>Información de gasto</Modal.Header>
			<Modal.Content>
         {selectedPayment ? ( <>
               <Input label='Gasto' value={selectedPayment.expense_id} readonly/>
               <Input label='Nombre del gasto' value={selectedPayment.expense_name} onChange={onEditChange('expense_name')}/>
               <Input label='Cantidad' value={selectedPayment.amount} onChange={onEditChange('amount')}/>
               <Input label='Descripción' value={selectedPayment.description} onChange={onEditChange('description')}/>
               <Input label='Creador' value={selectedPayment.email} readonly/>
               <Input
                  label='Fecha de creación'
                  readonly
                  value={moment.unix(selectedPayment.date_created).format('DD/MM/YY')}
               /> 
               <Checkbox label='Eliminado' checked={ selectedPayment.deleted } onChange={() => setSelectedPayment({ ...selectedPayment, deleted: !selectedPayment.deleted })}/>
               </> ) : null}
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cerrar' onClick={bindClick(setViewModal)} />
            {edit? <Button text='Guardar' color='black' onClick={editPayment}/> : null }
			</Modal.Actions>
		</Modal>
   </div>
}

export default ProjectPayments;