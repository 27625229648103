import React, { useEffect, useState } from 'react';
import { Button, Field, Input, Message, Header } from '../components';
import { bindClick, bindSimpleFormChange } from '../Util';
import { Modal, Checkbox, Icon } from 'semantic-ui-react';
import Validator from '../Validator';
import API from '../API';
import Toast from 'react-hot-toast';

interface User{
   user_id?: number,
   email: string,
   edit_access: boolean
}

var ProjectUsers = (props: any)=>{
   var { idProject, edit } = props;
   var [projectUsers, setProjectUsers] = useState<any[]>(null);
   var [loadError, setLoadError] = useState<string>(null);
   var [addModal, setAddModal] = useState<boolean>(false);
   var [editModal, setEditModal] = useState<boolean>(false);
   var [modalPrompts, setModalPrompts] = useState<string[]>(null);
   var [createForm, setCreateForm] = useState<User>({email: '', edit_access: false});
   var [selectedUser, setSelectedUser] = useState<User>(null)

   useEffect(()=>{
      get();
	}, []);

   var get = ()=>{
      API.getProjectUsers(idProject).then(res=>{
         if(res.error) return setLoadError(res.message);
         return setProjectUsers(res.data);
      }).catch(err=>{
         return setLoadError('Hubo un error al obtener los usuarios del proyecto.(GPUE-1)');
      })
   }

   var viewUser = async (user: User)=>{
      setSelectedUser(user);
      return setEditModal(true);
   }

   var editUser = (setLoading: (l: boolean)=>void)=>{
      setLoading(true);
      API.editProjectUser(idProject, selectedUser.user_id, selectedUser.edit_access).then(res=>{
         if(res.error) return Toast.error(res.message);
			setEditModal(false);
         get();
			return Toast.success('Usuario actualizado!');
      }).catch(err=>{
         return setModalPrompts(['Hubo un error al intentar actualizar el usuario. (LCL-1)']);
      }).finally(()=>{
			setLoading(false);
		})
   }

   var addUser = (setLoading: (l: boolean)=>void)=>{
      var { valid, prompts } = Validator(createForm, {
         email: [
            { rule: 'email', label: 'Correo electronico' },
         ]
      });
      setModalPrompts(prompts);
		if(!valid) return;
      setLoading(true);
      API.addProjectUser(idProject, createForm.email, createForm.edit_access).then(res=>{
         if(res.error) return setModalPrompts([res.message]);
			setAddModal(false);
         get();
			return Toast.success('Usuario agregado!');
      }).catch(err=>{
         return setModalPrompts(['Hubo un error al intentar agregar el usuario. (LCL-1)']);
      }).finally(()=>{
			setLoading(false);
		})
   }

   var removeUser = (setLoading: (l: boolean)=>void)=>{
      setLoading(true);
      API.removeProjectUser(idProject, selectedUser.user_id).then(res=>{
         if(res.error) return setModalPrompts([res.message]);
			setEditModal(false);
         get();
			return Toast.success('Usuario removido!');
      }).catch(err=>{
         return setModalPrompts(['Hubo un error al intentar remover el usuario. (LCL-1)']);
      }).finally(()=>{
			setLoading(false);
		})
   }

   var onCreateFormChange = bindSimpleFormChange(createForm, setCreateForm);
   var onEditFormChange = bindSimpleFormChange(selectedUser, setSelectedUser);

   if(loadError){
		return <Header text='Error' subtext={loadError} />
	}

	if(!projectUsers){
		return <Header loading text='Cargando datos...' />
	}

   return <div>
      <table className="fr selectable table" style={{ maxWidth: 500, margin: 'auto' }}>
         <thead>  
            <tr>
               <th className="title" colSpan={1}>Usuarios</th>
               <th colSpan={2} style={{ textAlign: 'right' }}>
                  {edit ? <Button size='tiny' color='black' iconName='plus' text='Agregar' onClick={() => setAddModal(true)}/> : null}
               </th>
            </tr>
            <tr>
               <th>ID</th>
               <th>Correo</th>
               <th>Editar</th>
            </tr>
         </thead>
         <tbody>
            {projectUsers.length === 0 ? (
               <tr>
                  <th colSpan={3} style={{ textAlign: 'center', padding: '10px' }}>
                     <b>Sin usuarios agregados.</b>
                  </th>
               </tr>
            ) : (
               projectUsers.map(user => (
                  <tr onClick={() => viewUser(user)}>
                     <td className='collapsing'><b>{user.user_id}</b></td>
                     <td>{user.email}</td>
                     <td className='collapsing' style={{ textAlign: 'center' }}><Icon name={user.edit_access ? "check" : 'remove'} color={user.edit_access ? 'green' : 'black'} /></td>
                  </tr>
               ))
            )}
         </tbody>
      </table>
      <Modal open={addModal} onClose={bindClick(setAddModal)} size='tiny'>
         <Modal.Header>Agregar usuario</Modal.Header>
         <Modal.Content>
            <Input label='Correo eléctronico' onChange={onCreateFormChange('email')} />
            <Checkbox label='Permiso de edición'  onChange={() => onCreateFormChange('edit_access')(!createForm.edit_access)}/>
            <Message type='error' list={modalPrompts} />
         </Modal.Content>
         <Modal.Actions>
            <Button text='Cerrar' basic onClick={bindClick(setAddModal)}/>
            <Button text='Agregar' color='black' onClick={addUser} />
         </Modal.Actions>
      </Modal>
      <Modal open={editModal} onClose={bindClick(setEditModal)} size='tiny'>
         <Modal.Header>Modificar</Modal.Header>
         <Modal.Content>
         {selectedUser ? (<>
            <Input label='Correo eléctronico' value={selectedUser.email} readonly/>
            <Checkbox label='Permiso de edición' checked={selectedUser.edit_access} onChange={() => onEditFormChange('edit_access')(!selectedUser.edit_access)}/>
         </>) : null }
         {edit ? <div style={{ textAlign: 'center'}}><Button text='Remover usuario' color='black' onClick={removeUser} size='small'/></div> : null }
         </Modal.Content>
         <Modal.Actions>
         <Button text='Cerrar' basic onClick={bindClick(setEditModal)}/>
         {edit ? <Button text='Actualizar' color='black' onClick={editUser} /> : null }
         </Modal.Actions>
      </Modal>
   </div>
}

export default ProjectUsers;  