import React, { useEffect, useState } from 'react';
import { Modal, Checkbox, Icon } from 'semantic-ui-react';
import { Button, Field, Input, Message, Header } from '../components';
import { addCommas, bindClick, bindSimpleFormChange } from '../Util';
import { SetLoading } from '../Classes';
import Validator from '../Validator';
import API from '../API';
import Toast from 'react-hot-toast';
import moment from 'moment';


interface IncomeForm{
   project_id: number,
   income_name: string,
   amount: number,
   description?: string
}

interface Income{
   income_id: number,
   income_name: string,
   description?: string,
   email: string,
   date_created: number,
   date_deleted?: number,
   amount: number,
   deleted: boolean
}

var ProjectIncomes = (props: any)=>{
   var { idProject, edit } = props;
   var [incomes, setIncomes] = useState<Income[]>(null);
   var [loadError, setLoadError] = useState<string>(null);
   var [createForm, setCreateForm] = useState<IncomeForm>({project_id: 0, income_name: '', amount: 0, description: ''});
   var [modalPrompts, setModalPrompts] = useState<string[]>(null);
   var [createModal, setCreateModal] = useState<boolean>(false);
   var [viewModal, setViewModal] = useState<boolean>(false);
   var [selectedIncome, setSelectedIncome] = useState<Income>(null);

   useEffect(()=>{
      get();
	}, []);

   var get = ()=>{
      API.getProjectIncomes(idProject).then(res=>{
         if(res.error){
            return setLoadError(res.message);
         }
         setIncomes(res.data);
      }).catch(err=>{
         return setLoadError('Hubo un error al obtener los ingresos del proyecto.(GPIE-1)');
      })
   }

   var viewIncome = async (income: Income)=>{
      setSelectedIncome(income);
      return setViewModal(true);
   }

   var createIncome = (setLoading: SetLoading)=>{
      var { valid, prompts } = Validator(createForm, {
			income_name: [
				{ rule: 'length', params: [4], label: 'Nombre' },
			],
         amount: [
				{ rule: 'number', label: 'Monto' },
			]
		});
		setModalPrompts(prompts);
		if(!valid) return;
		setLoading(true);
      API.createIncome( idProject, createForm.income_name, createForm.amount, createForm.description).then(res=>{
         if(res.error) return Toast.error(res.message);
         setCreateModal(false);
         Toast.success('Gasto creado con exito!');
         return get();
      }).catch(err=>{
         return Toast.error('Hubo un error al registrar el ingreso.(GPIE-2)');
      })
   }

   var editIncome = (setLoading: SetLoading)=>{
      var { valid, prompts } = Validator(selectedIncome, {
         income_id: [
            { rule: 'number', label: 'Numero de pago' }
         ],
			income_name: [
				{ rule: 'length', params: [4], label: 'Nombre' },
			],
         amount: [
				{ rule: 'number', label: 'Monto' },
			]
		});
		setModalPrompts(prompts);
		if(!valid) return;
		setLoading(true);
      API.editIncome( selectedIncome.income_id, selectedIncome.income_name, selectedIncome.amount, selectedIncome.description, selectedIncome.deleted).then(res=>{
         if(res.error) return Toast.error(res.message);
         setViewModal(false);
         Toast.success('Ingreso actualizado con exito!');
         return get();
      }).catch(err=>{
         return Toast.error('Hubo un error al actualizar el ingreso.(LPL-3)');
      }).finally(()=>{
			setLoading(false);
		})
   }

   var onCreateFormChange = bindSimpleFormChange(createForm, setCreateForm);
   var onEditChange = bindSimpleFormChange(selectedIncome, setSelectedIncome);

   if(loadError){
		return <Header text='Error' subtext={loadError} />
	}

	if(!incomes){
		return <Header loading text='Cargando datos...' />
	}

   return <div>
      <table className="fr selectable table" style={{ maxWidth: 800, margin: 'auto' }}>
         <thead>
            <tr>
               <th className="title" colSpan={4}>Ingresos</th>
               <th colSpan={2} style={{ textAlign: 'right' }}>
                  {edit ? <Button size='tiny' color='black' iconName='plus circle' text='Crear' onClick={bindClick(setCreateModal, true)} /> : null }
               </th>
            </tr>
            <tr>
               <th>ID</th>
               <th>Nombre</th>
               <th>Descripción</th>
               <th>Monto</th>
               <th>Creador</th>
               <th>Creación</th>
            </tr>
         </thead>
         <tbody>
            { incomes.length === 0 ? (
               <tr>
                  <th colSpan={8} style={{ textAlign: 'center', padding: '10px' }}>
                     <b>Sin ingresos registrados.</b>
                  </th>
               </tr>
            ) : (
               incomes.map(income => (
                  <tr onClick={() => viewIncome(income)}>
                     <td className='collapsing'><b>{income.income_id}</b></td>
                     <td>{income.income_name}</td>
							<td>
								{income.description && income.description.length>0 ? income.description : (
									<Icon color={'grey'} name="minus" />
									)}
							</td>
							<td className='collapsing'>{addCommas(income.amount)}</td>
                     <td className='collapsing'>{income.email}</td>
                     <td className='collapsing'>{moment.unix(income.date_created).format('DD/MM/YY')}</td>
                  </tr>
               ))
            )}
         </tbody>
      </table>
      <Modal open={createModal} onClose={bindClick(setCreateModal)} size='tiny'>
			<Modal.Header>Registrar ingreso</Modal.Header>
			<Modal.Content>
            <Input label='Nombre del ingreso' onChange={onCreateFormChange('income_name')} />
            <Input label='Cantidad' onChange={onCreateFormChange('amount')} />
            <Input label='Descripción' onChange={onCreateFormChange('description')} />
				<Message type='error' list={modalPrompts} />
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cerrar' basic onClick={bindClick(setCreateModal)} />
				<Button text='Crear' color='black' onClick={createIncome}/>
			</Modal.Actions>
		</Modal>
      <Modal open={viewModal} onClose={bindClick(setViewModal)} size='tiny'>
			<Modal.Header>Información de ingreso</Modal.Header>
			<Modal.Content>
         {selectedIncome ? ( <>
               <Input label='Gasto' value={selectedIncome.income_id} readonly/>
               <Input label='Nombre del gasto' value={selectedIncome.income_name} onChange={onEditChange('income_name')}/>
               <Input label='Cantidad' value={selectedIncome.amount} onChange={onEditChange('amount')}/>
               <Input label='Descripción' value={selectedIncome.description} onChange={onEditChange('description')}/>
               <Input label='Creador' value={selectedIncome.email} readonly />
               <Input
                  readonly
                  label='Fecha de creación'
                  value={moment.unix(selectedIncome.date_created).format('DD/MM/YY')}
               /> 
               <Checkbox label='Eliminado' checked={ selectedIncome.deleted } onChange={() => setSelectedIncome({ ...selectedIncome, deleted: !selectedIncome.deleted })}/>
               </> ) : null}
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cerrar' onClick={bindClick(setViewModal)} />
            {edit ? <Button text='Guardar' color='black' onClick={editIncome}/> : null }
			</Modal.Actions>
		</Modal>
   </div>
}

export default ProjectIncomes;