import React, { useEffect, useState } from 'react';
import { Button, Groupper, Input, Message } from '../components';
import { Modal } from 'semantic-ui-react';
import Validator from '../Validator';
import API from '../API';

interface ScreenParams{
	onLogin?: ()=>void;
}

var Login = (props: ScreenParams)=>{
	var [email, setEmail] = useState('');
	var [password, setPassword] = useState('');
	var [errorPrompts, setErrorPrompts] = useState<string[]>([]);

	useEffect(()=>{
		var login = API.getLogin();
		if(login && props.onLogin) return props.onLogin();
	}, []);

	var sendLogin = (setLoading: (l: boolean)=>void)=>{
		var { valid, prompts } = Validator({ email, password }, {
			email: [{
				rule: 'empty', label: 'Correo electrónico'
			}],
			password: [{
				rule: 'minLength', params: [5], label: 'Contraseña'
			}]
		});
		setErrorPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.login(email, password).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);
			props.onLogin();
		}).catch(err=>{
			setErrorPrompts(['Hubo un error inesperado haciendo login (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	return  <>
		<Groupper title='Login Headliner' width={500} style={{ marginTop: 50 }} titleCentered actions={(
			<>
				<Button text="Iniciar sesión" color="black" onClick={sendLogin} />
			</>
		)}>
			<Input label='Correo electrónico' value={email} onChange={setEmail} />
			<Input label='Contraseña' value={password} onChange={setPassword} inputType="password" />
			<Message type="error" list={errorPrompts} />
		</Groupper>
	</>
}

export default Login;