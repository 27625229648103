import React, { useState } from 'react';
import classNames from 'classnames';

export interface StatProps{
	value?: string,
	label?: string,
	className?: string,
	style?: React.CSSProperties
	size?: 'big',
	_inGroup?: boolean,
}

interface StatGroupProps extends StatProps{
	children: React.ReactNode[] | React.ReactNode
}

const StatGroup : React.FC<StatGroupProps> = (props: StatGroupProps)=>{
	var [randomCode, setRandomCode] = useState<number>(Math.floor(Math.random()*100000));
	var childs = (Array.isArray(props.children) ? props.children : [props.children]).map((a,i)=>{
		if(React.isValidElement(a)){
			return React.cloneElement(a, {
				...a.props,
				key: `stgr-${randomCode}-${i}`,
				_inGroup: true,
			} as unknown)
		}
	});

	return <div className={classNames('fr stats', props.size)}>
		{childs}
	</div>
}

type StatSubComponents = {
	Group: typeof StatGroup
}

const Stat : React.FC<StatProps> & StatSubComponents = (props: StatProps)=>{
	return <div className={classNames('stat', props.className, {
		fr: !props._inGroup
	})} style={props.style}>
		<div className="value">{props.value}</div>
		<div className="label">{props.label}</div>
	</div>
}

Stat.Group = StatGroup;

export default Stat;