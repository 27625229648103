import React, { LegacyRef, useEffect, useRef, useState } from 'react';
import { Label } from 'semantic-ui-react';

interface ValueLabelProps{
   label?: string,
   value?: string | number,
   color?: any
}

var ValueLabel = ((props: ValueLabelProps)=>{
   return <>
      <Label style={{width:'100%', marginBottom: 10, display:'flex', flexDirection: 'column', borderRadius: 10, backgroundColor: '#F4F4F4'}}>
         <span style={{fontSize: 12, fontWeight:'normal', padding: 3}}>{props.label}</span>
         <span style={{fontSize: 22, color:'black', padding: 3 }}>{props.value}</span>
      </Label>
   </>
})

export default ValueLabel;