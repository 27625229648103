import React, { PropsWithChildren, CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';

interface ComponentProps extends PropsWithChildren{
	title?: string,
	titleRight?: string,
	style?: CSSProperties,
	width?: number,
	actions?: ReactNode,
	fitted?: boolean,
	className?: string,
	titleSize?: 'big',
	titleCentered?: boolean,
	defaultStyle?: boolean,
}

var Groupper = (props: ComponentProps)=>{
	return <div className={classNames('fr groupper', props.className, {
		fitted: props.fitted,
	})} style={{
		...props.defaultStyle!==false ? {
			margin: 'auto',
			maxWidth: props.width || 1000,
			width: '100%',
		} : null,
		...props.style
	}}>
		{props.title && <div className={classNames('head', props.titleSize, {
			centered: props.titleCentered
		})}>
			<div className="left">
				{props.title}
			</div>
			{props.titleRight ? (
				<div className="right" style={{ fontSize: 14 }}>{props.titleRight}</div>
			) : null}
		</div>}
		{props.children}
		{props.actions ? (
			<div className="actions">
				{props.actions}
			</div>
		) : null}
	</div>
}

export default Groupper;