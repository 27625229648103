import React, { useEffect, useState } from 'react';
import API from '../API';
import Toast from 'react-hot-toast';
import { useParams, useNavigate } from 'react-router-dom';
import { Header, SidebarContainer } from '../components';

import ProjectInfo from './ProjectInfo';
import ProjectPayments from './ProjectPayments';
import ProjectIncomes from './ProjectIncomes';
import ProjectUsers from './ProjectUsers';

interface ProjectInfoClass{
	project_name: string,
	project_id: number
}

var Project = ()=>{
	var { projectId } = useParams();
	var [loadError, setLoadError] = useState<string>(null);
	var [projectInfo, setProjectInfo] = useState<ProjectInfoClass>(null);
	var [option, setOption] = useState<number>(1);
	var [ edit , setEdit ] = useState<boolean>(true);
	const navigate = useNavigate();

	useEffect(()=>{
		get();
	}, []);

	var get = async()=>{
		API.getProject(parseInt(projectId)).then(res=>{
			if(res.error || !res.data ){
				return navigate(`/`);
			}
			setEdit(res.data.edit_access);
			return setProjectInfo(res.data);
		}).catch(err=>{
			return setLoadError('Hubo un error al obtener el proyecto.(GPE-1)');
		});
	}

	if(loadError){
		return <Header text='Error' subtext={loadError} />
	}

	if(!projectInfo){
		return <Header loading text='Cargando datos...' />
	}

	return <SidebarContainer 
		header={projectInfo.project_name}
		routes={[
			{ url: `/:projectId/income`, render: ()=><ProjectIncomes	idProject={projectId} edit={edit} /> },
			{ url: `/:projectId/expenses`, render: ()=><ProjectPayments idProject={projectId} edit={edit} /> },
			{ url: `/:projectId/users`, render: ()=><ProjectUsers idProject={projectId} edit={edit} /> },
			{ url: `/:projectId`, render: ()=><ProjectInfo idProject={projectId} edit={edit} /> },
		]}
		items={[
			{ text: 'Proyecto', 	icon: 'wallet', 					url: `/${projectId}` },
			{ text: 'Ingresos', 	icon: 'money bill wave', 		url: `/${projectId}/income` },
			{ text: 'Gastos', 	icon: 'hand holding usd', 		url: `/${projectId}/expenses` },
			{ text: 'Usuarios', 	icon: 'users', 					url: `/${projectId}/users` },
		]}
	/>
}

export default Project;
