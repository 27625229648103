import axios from 'axios';
import { APIResponse, UserLoginData } from './Classes';
const ROOT_URL = process.env.REACT_APP_API_URL
const DEBUG = process.env.REACT_APP_DEBUG;

var CACHE : { [x: string]: APIResponse<any> } = {};

var getLogin = () : UserLoginData=>{
	var jsonstr = localStorage.getItem('user_data');
	if(!jsonstr) return null;
	try{
		var data = JSON.parse(jsonstr) as UserLoginData;
		if(!data) return null;
		return data;
	}catch(e){
		return null;
	}
}

var logout = ()=>{
	localStorage.removeItem('user_data');
	window.dispatchEvent(new Event('user_data'));
	window.dispatchEvent(new Event('user_logout'));
}

async function post<T=any>(endpoint: string, data?: any, login: boolean=true) : Promise<APIResponse<T>>{
	var options = {
		headers: {
			'token': '',
			'Content-Type': 'application/json'
		}
	}
	if(login){
		var u = getLogin();
		if(u===null) return { error: true, code: 'PV-INVCRD', message: 'Las credenciales de accceso no son válidas (LCL-TKNCH-L).' }
		options.headers.token = u.token;
	}
	if(data instanceof FormData){
		options.headers['Content-Type'] = 'multipart/form-data';
	}

	try{
		var ENDPOINT = `${ROOT_URL}/${endpoint}`;
		if(DEBUG) console.log("POST "+ENDPOINT);
		var res = await axios.post(ENDPOINT, data, options);
		if(DEBUG) console.log(` => Response: ${(res.headers['content-length']/1024).toFixed(2)} KB`);
		if(res.data && res.data.error && res.data.code=='P-TKNE', res.data.code=='AP-VFYT-1'){
			logout();
		}
		return res.data as APIResponse<T>;
	}catch(err){
		if(DEBUG) console.error(err);
		return {
			error: true, 
			message: 'Hubo un error inesperado (API-LCL-1)',
			code: 'API-LCL-1',
		}
	}
};

var cachePost = async <T>(endpoint: string, key: string, data?: any) : Promise<APIResponse<T>>=>{
	if(CACHE[key]) return CACHE[key] as APIResponse<T>;
	var res = await post(endpoint, data);
	if(!res.error) CACHE[key] = res;
	return res;
};

var login = async (email: string, password: string)=>{
	var res = await post<UserLoginData>('login', { email, password }, false);
	if(!res.error && res.data.token){
		localStorage.setItem('user_data', JSON.stringify(res.data));
		window.dispatchEvent(new Event('user_data'));
	}
	return res;
};

var projectPermissions = async(project_id: number)=>{
	return await post('projects/user/permissions', { project_id }, true);
};

var list = async()=>{
	return await post('projects/list',{}, true);
};

var register = async(email: string, password: string, create_project: boolean)=>{
	return await post<UserLoginData>('users/register', { email, password, create_project}, true);
};

var createProject = async(project_name: string, external_id?: string, description?: string)=>{
	return await post<{ project_id: number }>('projects/create', { project_name, external_id, description }, true);
};

var getProject = async(project_id: number)=>{
	return await post('projects/get', { project_id }, true);
};

var getProjectPayments = async(project_id: number)=>{
	return await post('projects/payments', { project_id }, true);
};

var createPayment = async(project_id: number, expense_name: string, amount: number, description?: string)=>{
	return await post('projects/payments/create', { project_id, expense_name, amount, description }, true);
};

var editPayment = async(expense_id: number, expense_name: string, amount: number, description?: string, deleted?: boolean)=>{
	return await post('projects/payments/edit', { expense_id, expense_name, amount, description, deleted }, true);
};

var editProject = async(project_id: number, project_name: string, external_id?: string, description?: string, deleted?: boolean)=>{
	return await post('projects/edit', { project_id, project_name, external_id, description, deleted }, true);
};

var getProjectIncomes = async(project_id: number)=>{
	return await post('projects/incomes', { project_id }, true);
};

var createIncome = async(project_id: number, income_name: string, amount: number, description?: string)=>{
	return await post('projects/incomes/create', { project_id, income_name, amount, description }, true);
};

var editIncome = async(income_id: number, income_name: string, amount: number, description?: string, deleted?: boolean)=>{
	return await post('projects/incomes/edit', { income_id, income_name, amount, description, deleted }, true);
};

var getProjectUsers = async(project_id: number)=>{
	return await post('projects/users', { project_id }, true);
};

var addProjectUser = async(project_id: number, email: string, edit_access: boolean)=>{
	return await post('projects/users/add', { project_id, email, edit_access }, true);
};

var removeProjectUser = async(project_id: number, user_id: number)=>{
	return await post('projects/users/remove', { project_id, user_id }, true);
};

var editProjectUser = async(project_id: number, user_id: number, edit_access: boolean)=>{
	return await post('projects/users/edit', { project_id, user_id, edit_access }, true);
};

var userPasswordChange = async(password: string, new_password: string)=>{
	return await post('users/password', { password, new_password }, true);
};

export default {
	addProjectUser,
	createIncome,
	createPayment,
	createProject,
	editIncome,
	editPayment,
	editProject,
	editProjectUser,
	getLogin,
	getProject,
	login,
	list,
	logout,
	register,
	getProjectIncomes,
	getProjectPayments,
	getProjectUsers,
	projectPermissions,
	userPasswordChange,
	removeProjectUser
}