const bindClick = <T>(setVal: (val: T)=>void, val: T=null)=>{
	return ()=>{
		setVal(val);
	}
}

const bindChange = (setVal: (val: any, ...args: any)=>void, ...args: any)=>{
	return (e: { target: { value: string } })=>{
		return setVal(e.target.value);
	}
}

const bindSemantic = (setVal: (val: any)=>void)=>{
	return (e: any, data: { value?: any, checked?: any })=>{
		return setVal(data.checked || data.value);
	}
}

const bindSemanticForm = (val: any, setVal: (val: any)=>void)=>{
	return (e: any, data: { name?: string, value?: any, checked?: boolean })=>{
		if(data.name && typeof data.checked!=='undefined'){
			setVal({ ...val, [data.name]: data.checked });
		}else if(data.name){
			setVal({ ...val, [data.name]: data.value });
		}
	}
}

const bindToggle = (val: any, setVal: any)=>{
	return ()=>{
		setVal(!val);
	}
}

const bindFormChange = (val: any, setVal: (val: any, ...args: any)=>void)=>{
	return (e: { target: { value: string, name: string } })=>{
		setVal({ ...val, [e.target.name]: e.target.value })
	}
}

const bindSimpleFormChange = <T>(val: T, setVal: (val: any)=>void)=>{
	return (name: keyof T)=>{
		return (v: any)=>{
			setVal({ ...val, [name]: v });
		}
	}
}

function addCommas(num: number, fixed: boolean=true, hideZero: boolean=false){
	if (!num && num !== 0) return 'ERR';
	if(typeof num==='string'){
		num = parseFloat(num as string);
	}
	var c = num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	var dec = c.substring(c.length-2, c.length);
	return fixed ? (hideZero && dec == '00' ? c.slice(0, -3) : c) : c.slice(0, -3);
}

function unix(){
	return Math.floor(new Date().getTime()/1000);
}

function isActive(date_start: number, date_end: number){
	var now = unix();
	return now>=date_start && now<=date_end;
}

function uniqueArray<T>(array: T[]){
	return Array.from<T>(new Set(array));
}

function groupBy<T>(objs: T[], key: keyof T, inherit: (keyof T)[]=null){
	var k = uniqueArray(objs.map(a=>a[key]));
	var result = [];
	for(let i of k){
		var r : any = {};
		r[key] = i;
		r['values'] = objs.filter(a=>a[key]==i);
		result.push(r);
	}
	if(inherit && Array.isArray(inherit)){
		for(let i of result){
			for(let j of inherit){
				i[j] = i['values'][0][j];
			}
		}
	}
	return result as any[];
}

function validatePassword(val: string){
	return (/^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*\.\,\+\*])[a-zA-Z0-9!@#$%^&*\.\,\+\*]{8,32}$/).test(val);
}

function partition<T>(a: T[], n: number) : T[][]{
	var array = [...a];
	return array.length ? [array.splice(0, n)].concat(partition(array, n)) : [];
}

function cropString(str: string, len: number, elip: boolean=false){
	var elip = elip && str.length>len;
	return str.trim().substring(0, len-(elip ? 3 : 0)).trim() + (elip ? '...' : '');
}

function randomRange(min: number, max: number){
	return Math.floor(Math.random() * (max-min+1)+min);
}

export {
	addCommas,
	bindChange,
	bindClick,
	bindFormChange,
	bindSemantic,
	bindSemanticForm,
	bindSimpleFormChange,
	bindToggle,
	cropString,
	groupBy,
	isActive,
	partition,
	randomRange,
	uniqueArray,
	unix,
	validatePassword,
}