import React, { useEffect, useState } from 'react';
import { CDN_ROOT } from '../SiteConfig';
import { Link, useLocation } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { Button } from '../components'
import API from '../API';
import { UserLoginData } from '../Classes';


var Navbar = ()=>{
	var [sidebar, setSidebar] = useState<boolean>(false);
	var location = useLocation();
	var [user, setUser] = useState<UserLoginData>(null);

	useEffect(()=>{
		setUser(API.getLogin());
		setSidebar(false);
	}, [location]);

	const NAVBAR_ITEMS = [
		{ text: 'Proyectos', href: '/' },
		{ text: 'Mi Cuenta', href: '/perfil' }
	]
	if (user?.create_user) {
		NAVBAR_ITEMS.push({ text: 'Registrar Usuario', href: '/register' });
	}
	
	return <>
		<div className="ar navbar">
			<div className="left">
				{/* <div className="hamburger item" onClick={()=>setSidebar(!sidebar)}>
					<Icon name={!sidebar ? 'bars' : 'remove'} />
				</div> */}
				<Link to="/" className="logo item">
					Headliner
					{/* <img src={`${CDN_ROOT}/assets/logo/LogoH_Small.webp`} alt="" /> */}
				</Link>
				{NAVBAR_ITEMS.map((a, i)=>(<Link className="item" to={a.href}  key={`navbar-itm-${i}`}>{a.text}</Link>))}
			</div>
			<div className="right">
				<div className='item desktop' onClick={API.logout}>Cerrar Sesión</div>
				<div className='item mobile' onClick={API.logout}>
					<Icon name='sign out' style={{ margin: 0 }} />
				</div>
			</div>
		</div>
		{sidebar && <>
			<div className="ar navsidebar">
				{NAVBAR_ITEMS.map((a, i)=>(<Link className="item" to={a.href} key={`sidebar-itm-${i}`}>{a.text}</Link>))}
			</div>
			<div className="ar closable" onClick={()=>setSidebar(false)}>asd</div>
		</>}
	</>
}

export default Navbar;