import React, { useEffect, useState } from 'react';
import { Button, Input, Message, Header, Groupper } from '../components';
import { UserLoginData } from '../Classes';
import { bindSimpleFormChange, bindClick } from '../Util';
import { Checkbox } from 'semantic-ui-react';
import Validator from '../Validator';
import API from '../API';
import Toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

interface RegisterForm{
   email: string,
   password: string,
   create_project: boolean
}

var UserRegister = ()=>{
   var [user, setUser] = useState<UserLoginData>(null);
   var [registerForm, setRegisterForm] = useState<RegisterForm>({email: '', password:'', create_project: false});
   var [errorPrompts, setErrorPrompts] = useState<string[]>([]);
   var navigate = useNavigate();


   useEffect(() => {
      (async () => {
          var user = await API.getLogin();
          setUser(user);
          if (!user.create_user) {
              return navigate('/');
          }
      })();
  }, []);
   
   var register = (setLoading: (l: boolean)=>void)=>{
		var { valid, prompts } = Validator( registerForm, {
			email: [{
				rule: 'email', label: 'Correo eletrónico'
			}],
			password: [{
				rule: 'minLength', params: [6], label: 'Contraseña'
			}],
		});
		setErrorPrompts(prompts);
		if(!valid) return;
      API.register(registerForm.email, registerForm.password, registerForm.create_project).then(res=>{
         if(res.error) return setErrorPrompts([res.message]);
			return Toast.success('Usuario registrado!');      
      }).catch(err=>{
         return Toast.error('Hubo un error al intentar registrar el usuario. (LCL-1)');
      }).finally(()=>{
			setLoading(false);
		})
   }

	if(!user){
		return <Header loading text='Cargando datos...' />
	}

	var onRegisterFormChange = bindSimpleFormChange(registerForm, setRegisterForm);

   return <div>
      <Groupper title='Registrar Usuario' width={500} titleCentered actions={(
			<Button text="Registrar" color="black" onClick={register}/>
		)}>
         <Input label='Correo electrónico' inputType='email' onChange={onRegisterFormChange('email')} autocomplete={false} />
         <Input label='Contraseña' inputType='text' onChange={onRegisterFormChange('password')} autocomplete={false} />
         <div style={{ textAlign: 'left', marginTop: 15 }}>
            <Checkbox label='Crear proyectos' onChange={() => onRegisterFormChange('create_project')(!registerForm.create_project)}/>
         </div>
         <Message type="error" list={errorPrompts} />
      </Groupper>
   </div>
}


export default UserRegister;