import React, { useEffect, useState } from 'react';
import { Modal, Progress, Item } from 'semantic-ui-react';
import { Button, Input, Message, Header } from '../components';
import { addCommas, bindClick, bindSimpleFormChange } from '../Util';
import { UserLoginData } from '../Classes';
import Validator from '../Validator';
import API from '../API';
import Toast from 'react-hot-toast';
import { useNavigate } from "react-router-dom";


interface ProjectForm{
	project_name: string,
	description?: string,
	external_id?: string,
}

var UserProjects = ()=>{
	var navigate = useNavigate();
	var [createModal, setCreateModal] = useState<boolean>(false);
	var [createForm, setCreateForm] = useState<ProjectForm>(null);
	var [modalPrompts, setModalPrompts] = useState<string[]>(null);
	var [projects, setProjects] = useState<any[]>(null);
	var [user, setUser] = useState<UserLoginData>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [windowWidth, setWindowWidth] = useState(window.innerWidth);
	
	useEffect(() => {
		setUser(API.getLogin());
		get();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	var viewProject = (project_id: number)=>{
		return ()=>{
			navigate(`/${project_id}`);
		}
	}

	var get = ()=>{
		API.list().then(res=>{
			if(res.error) return setLoadError(res.message);
			return setProjects(res.data);
		}).catch(err=>{
			return setLoadError('Hubo un error al obtener los proyectos. (GPE-1)');
		});
	}

	var createProject = (setLoading: (l: boolean)=>void)=>{
		var { valid, prompts } = Validator(createForm, {
			project_name: [
				{ rule: 'length', params: [6], label: 'Nombre' },
				{ rule: 'empty', label: 'Nombre' },
			],
			external_id: [
				{ rule: 'number', label: 'IDCalendario', skipEmpty: true }
			],
		});
		setModalPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.createProject(createForm?.project_name, createForm?.external_id, createForm?.description).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			setCreateModal(false);

			if(createForm.external_id && createForm.external_id.length>0){
				setProjects(null);
				get();
			}else{
				var pj = [...projects];
				pj.push({
					project_id: res.data.project_id,
					...createForm,
					incomes: 0,
					expenses: 0,
					daily_income: 0,
				});
				setProjects(pj);
			}
			
			return Toast.success('Proyecto creado!');
		}).catch(err=>{
			return setModalPrompts(['Hubo un error al intentar crear el proyecto. (LCL-1)'])
		}).finally(()=>{
			setLoading(false);
		});
	}

	var handleResize = () => {
		setWindowWidth(window.innerWidth);
	 };
	
	var onCreateFormChange = bindSimpleFormChange(createForm, setCreateForm);

	if(loadError){
		return <Header text='Error' subtext={loadError} />
	}

	if(!projects || !user){
		return <Header loading text='Cargando datos...' />
	}

return <div>
	<table className="fr selectable table" style={{ maxWidth: 700, margin: 'auto' }}>
		 <thead>
			  <tr>
					<th className="title" colSpan={windowWidth < 768 ? 2 : 5}>Proyectos</th>
					<th colSpan={2} style={{ textAlign: 'right' }}>
						 {user.create_project ? <Button size='tiny' color='black' iconName='plus circle' text='Crear' onClick={bindClick(setCreateModal, true)} /> : null}
					</th>
			  </tr>
		 </thead>
		 <tbody>
			  {!projects ? (
					<tr>
						 <td colSpan={7} className="center aligned">
							  <div className="ui active centered inline loader"></div>
						 </td>
					</tr>
			  ) : projects.length === 0 ? (
					<tr>
						 <td className='center aligned' colSpan={7}>Sin Proyectos</td>
					</tr>
			  ) : (
					<td colSpan={7}>
						 <Item.Group style={{ padding: 5 }}>
							  {projects.map(project => (
									<Item key={project.id} style={{ backgroundColor: '#F4F4F4', padding: 5, borderRadius: 8, cursor: 'pointer' }} size='tiny' onClick={viewProject(project.project_id)}>
										 <Item.Image style={{ borderRadius: 12 }} size='small' src='https://th.bing.com/th/id/R.6df5c647fda413995e816bb708652c38?rik=XLwMXtsK%2bAnk%2bQ&pid=ImgRaw&r=0'/>
										 <Item.Content verticalAlign='middle'>
											  <Item.Header style={{ fontSize: 16, fontWeight: 'bold', color: 'black' }}>{project.project_name}</Item.Header>
											  <Item.Description style={{ fontSize: 18, color: 'black', fontWeight: 'bold', marginTop: 2 }}>${addCommas(project.incomes - project.expenses)}</Item.Description>
											  <Item.Extra>
													<Progress value={project.incomes} total={project.expenses} progress indicating precision={2} />
											  </Item.Extra>
										 </Item.Content>
									</Item>
							  ))}
						 </Item.Group>
					</td>
			  )}
		 </tbody>
	</table>
	<Modal open={createModal} onClose={bindClick(setCreateModal)} size='tiny'>
		 <Modal.Header>Crear proyecto</Modal.Header>
		 <Modal.Content>
			  <Input label='Nombre del proyecto' onChange={onCreateFormChange('project_name')} />
			  <Input textarea label='Descripción' onChange={onCreateFormChange('description')} placeholder='Descripción del proyecto' />
			  {user.create_external ? <Input label='ID Calendario' onChange={onCreateFormChange('external_id')} comment='(Opcional) ID del calendario de AREMA para hacer la recopilación automática de ingresos' /> : null }
			  <Message type='error' list={modalPrompts} />
		 </Modal.Content>
		 <Modal.Actions>
			  <Button text='Cerrar' basic onClick={bindClick(setCreateModal)} />
			  <Button text='Crear' color='black' onClick={createProject} />
		 </Modal.Actions>
	</Modal>
</div>
}

export default UserProjects;