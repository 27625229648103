import React, { useEffect, useState } from 'react';
import moment from 'moment';
import API from '../API';
import { Groupper, Header, Message, Button, Input, ValueLabel } from '../components';
import Toast from 'react-hot-toast';
import Validator from '../Validator';
import { Modal, Checkbox, Statistic, Progress, Label } from 'semantic-ui-react';
import { addCommas, bindClick, bindSimpleFormChange } from '../Util';
import { SetLoading, UserLoginData } from '../Classes';

interface ProjectData{
   project_id: number,
   project_name: string,
   external_id?: string,
   description?: string,
	daily_income?: number,
	deleted: boolean,
   email: string,
   date_created: number,
	expenses: number,
	incomes: number,
	incomes_extra: number,
	sales: number
}

var ProjectInfo = (props: any)=>{
   var { idProject, edit } = props;
   var [projectData, setProjectData] = useState<ProjectData>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [editModal, setEditModal] = useState<boolean>(false);
	var [modalPrompts, setModalPrompts] = useState<string[]>(null);

   useEffect(()=>{
      get();
	}, []);

	var get = async()=>{
		API.getProject(parseInt(idProject)).then(res=>{
			if(res.error) return setLoadError(res.message);
			setProjectData(res.data);
		}).catch(err=>{
			return setLoadError('Hubo un error al obtener la información del proyecto. (GPIE-1)');
		});
	}

	var editProject = (setLoading: (l: boolean)=>void)=>{
		var { valid, prompts } = Validator(projectData, {
			project_name: [
				{ rule: 'length', params: [4], label: 'Nombre' },
			],
			external_id: [
				{ rule: 'number', label: 'IDCalendario', skipEmpty: true }
			],
		});
		setModalPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.editProject(projectData.project_id, projectData.project_name, projectData.external_id, projectData.description, projectData.deleted).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			setEditModal(false);
			return Toast.success('Proyecto actualizado!');
		}).catch(err=>{
			return setModalPrompts(['Hubo un error al intentar crear el proyecto. (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}
	
	var onCreateFormChange = bindSimpleFormChange(projectData, setProjectData);

	if(loadError){
		return <Header text='Error' subtext={loadError} />
	}

	if(!projectData){
		return <Header loading text='Cargando datos...' />
	}

   return <> 
		<Groupper title='Resumen' width={350}>
			<ValueLabel label='Inversión' value={addCommas(projectData.expenses || 0, true, true)}/>
			<ValueLabel label='Ingresos' value={addCommas(projectData.incomes || 0, true, true)}/>
			<ValueLabel label='Ventas' value={addCommas(projectData.sales || 0, true, true)}/>
			<ValueLabel label='Ingresos extra' value={addCommas(projectData.incomes_extra || 0, true, true)}/>
			<ValueLabel label='Ingreso diario' value={addCommas(projectData.daily_income || 0, true, true)}/>
			<ValueLabel label='Balance' value={addCommas(projectData.incomes-projectData.expenses, true, true)}/>
			<Progress style={{ marginTop: 20 }} label={'Balance'} value={projectData.incomes} total={projectData.expenses} progress indicating />
		</Groupper>
		<Groupper title='Detalles de proyecto' width={350} style={{ marginTop: 20 }} fitted={true}>
			<table className='ui table details striped' style={{marginTop:'0'}}>
				<tbody>
					<tr>
						<td>Proyecto</td>
						<td>{projectData.project_id}</td>
					</tr>
					<tr>
						<td>IdCalendario</td>
						<td>{projectData.external_id || 'Sin IDCalendario'}</td>
					</tr>
					<tr>
						<td>Nombre</td>
						<td>{projectData.project_name}</td>
					</tr>
					<tr>
						<td>Creador</td>
						<td>{projectData.email}</td>
					</tr>
					<tr>
						<td className='collapsing'>Fecha creación</td>
						<td>{moment.unix(projectData.date_created).format('DD/MM/YY')}</td>
					</tr>
					<tr>
						<td>Descripción</td>
						<td>{projectData.description? projectData.description : 'Sin Descripción'}</td>
					</tr>
				</tbody>
				{edit ? <tfoot>
					<tr>
						<th colSpan={2} style={{textAlign: 'right'}}>
							<Button size='tiny' color='black' iconName='pen' text='Editar' onClick={() => setEditModal(true)}/>
						</th>
					</tr>
				</tfoot> : null}
			</table>
		</Groupper>
		<Modal open={editModal} onClose={bindClick(setEditModal)} size='tiny'>
			<Modal.Header>Crear proyecto</Modal.Header>
			<Modal.Content>
				<Input label='Nombre del proyecto' value={ projectData.project_name }  onChange={onCreateFormChange('project_name')}/>
				<Input label='Descripción' value={ projectData.description } onChange={onCreateFormChange('description')}/>
				<Input label='idCalendario' value={ projectData.external_id } onChange={onCreateFormChange('external_id')}/>
				<Checkbox label='Cancelado' checked={ projectData.deleted } onChange={() => onCreateFormChange('deleted')(!projectData.deleted)}/>
				<Message type='error' list={modalPrompts} />
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cerrar' basic onClick={bindClick(setEditModal)}/>
				<Button text='Guardar' color='black' onClick={editProject} />
			</Modal.Actions>
		</Modal>
	</>
}

export default ProjectInfo;