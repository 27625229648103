import React, { useEffect, useState } from 'react';
import API from '../API';
import { Groupper, Header, Message, Button, Input } from '../components';
import Toast from 'react-hot-toast';
import Validator from '../Validator';
import { Modal, Checkbox } from 'semantic-ui-react';
import { bindClick, bindSimpleFormChange } from '../Util';
import { UserLoginData } from '../Classes';


import moment from 'moment';

interface PasswordForm{
   old_password: string,
   new_password: string,
   new_password_confirm: string
}

var UserProfile = ()=>{
   var [user, setUser] = useState<UserLoginData>(null);
   var [loadError, setLoadError] = useState<string>(null);
   var [passwordForm, setpasswordForm] = useState<PasswordForm>({old_password: '', new_password:'', new_password_confirm:''});
   var [errorPrompts, setErrorPrompts] = useState<string[]>([]);

   useEffect(()=>{
		setUser(API.getLogin());
	}, []);

   
   var onpasswordFormChange = bindSimpleFormChange(passwordForm, setpasswordForm);


   var changePassword = (setLoading: (l: boolean)=>void)=>{
		var { valid, prompts } = Validator( passwordForm, {
			old_password: [{
				rule: 'empty', label: 'Contraseña actual'
			}],
			new_password: [{
				rule: 'minLength', params: [5], label: 'Contraseña'
			}],
         new_password_confirm: [{
				rule: 'empty', label: 'Repetir Contraseña'
			}]
		});
		setErrorPrompts(prompts);
		if(!valid) return;
      if(passwordForm.new_password.localeCompare(passwordForm.new_password_confirm)) return prompts.push('La contraseña nueva no coincide.')
      API.userPasswordChange(passwordForm.old_password, passwordForm.new_password).then(res=>{
         if(res.error) return Toast.error(res.message);
			return Toast.success('Contraseña actualizada!');
      }).catch(err=>{
         return Toast.error('Hubo un error al intentar actualizar la contraseña. (LCL-1)');
      }).finally(()=>{
			setLoading(false);
		})
	}

   if(loadError){
		return <Header text='Error' subtext={loadError} />
	}

	if(!user){
		return <Header loading text='Cargando datos...' />
	}

   return <div>
      <Groupper title='Mi Cuenta' width={500} fitted={true}>
         <table className='ui table details striped' style={{marginTop:'0'}}>
            <tbody>
               <tr>
                  <td>Usuario</td>
                  <td>{user.user_id}</td>
               </tr>
               <tr>
                  <td>Email</td>
                  <td>{user.email}</td>
               </tr>
               <tr>
                  <td>Fecha de creación</td>
                  <td>{moment.unix(user.date_created).format('DD/MM/YY')}</td>
               </tr>
            </tbody>
         </table>   
      </Groupper>
      <Groupper title='Contraseña' width={500} style={{marginTop: '20px'}} titleCentered actions={(
			<>
				<Button text="Cambiar" color="black" onClick={changePassword} />
			</>
		)}>
         <Input label='Constraseña actual' onChange={onpasswordFormChange('old_password')} inputType='password'/>
         <Input label='Nueva contraseña' onChange={onpasswordFormChange('new_password')} inputType='password'/>
         <Input label='Repetir contraseña' onChange={onpasswordFormChange('new_password_confirm')} inputType='password'/>
         <Message type="error" list={errorPrompts} />
      </Groupper>
   </div>
}

export default UserProfile;